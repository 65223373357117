$blue:    #4190BC !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #DC3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #FFAB07 !default;
$green:   #28A745 !default;
$teal:    #20c997 !default;
$cyan:    #215576 !default;

@import '~bootstrap/scss/bootstrap.scss';

.btn {
    color: white;
}

.btn:hover {
    color: white;
}

.nav-link {
    cursor: pointer;
}

.nav-link,.navbar-toggler {
    color: white;
    border-color: white;
}

.nav-link:hover {
    color: #e9ecef;
}

h1 {
    font-family: Oswald;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 30px;
}

h5 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

thead, tbody, tfoot, tr, td, th {
    border: none;
}

.dropdown-toggle::after {
    margin:0px;
}