
.popup-box {
  position: fixed;
  width: 100%;
  height: 600px;
  top: 0;
  left: 0;
  margin-top: 50px;
  z-index: 1;
}
   
.box {
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background:white;
  border-radius: 6.25px;
  padding: 20px 0;
  border: 1px solid #999;
  overflow: auto;
}

.popup-content-box {
  padding: 0 30px;
}

.popup-content-box-spaced {
  padding: 30px;
}
   
.close-icon {
  color: #00000080;
  content: 'X';
  cursor: pointer;
  position: fixed;
  right: calc(20% - 25px);
  top: calc(100vh - 85vh - 15px);
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 25px;
  margin-top: 65px;
  font-weight: bold;
}

.popup-title {
  color: #212529;
  font-weight: bold;
  text-align: center;
}

.popup-line {
  color: #212529;
}

.popup-description {
  color: #212529;
  text-align: center;
  margin: 0;
}

.popup-code {
  color: #215576;
  font-weight: bold;
  font-size: 31.26px;
}

.popup-footer {
  color: #215576;
  font-style: italic;
}

.popup-clickable-text {
  color: #215576;
  font-style: italic;
  cursor: pointer;
}

@media print {
  .popup-box {
    display: none;
  }
}
  
@media only screen and (max-width: 600px) {
  .popup-box {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: 50px;
    z-index: 1;
  }
  .box {
    width: 80%;
    margin: 0 auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background:white;
    border-radius: 6.25px;
    padding: 20px 0;
    border: 1px solid #999;
    overflow: auto;
  }

  .close-icon {
    color: #00000080;
    content: 'X';
    cursor: pointer;
    position: fixed;
    right: calc(18% - 30px);
    top: calc(100vh - 85vh - 30px);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
    margin-top: 50px;
    margin-top: 65px;
    font-weight: bold;
  }
}
