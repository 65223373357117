@font-face {
  font-family: Oswald;
  src: url(../fonts/Oswald-Regular.ttf);
}

@font-face {
  font-family: Oswald;
  src: url(../fonts/Oswald-Bold.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Medium.ttf);
  font-weight: medium;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Light.ttf);
  font-weight: normal;
}

html, body {
  color-scheme: light;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: 100%;
}

h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
}

.btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color:#FFFFFF;
}

.navbar-app {
  display:flex;
  align-content: space-between;
  background: linear-gradient(113.96deg, #4190BC 22.13%, #215576 122.16%);
  height: 126px;
}

.hidden-filter {
  display: none;
}

.patient-table-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-between;
}

.patient-table-header-item {
  display: flex;
  align-items: center;
  width: 500px;
}

.dropdown-menu {
  opacity: 100 !important;
  color: black;
}

.dropdown-menu a{
  color: black;
}


.react-bootstrap-table-page-btns-ul {
  justify-content: flex-end;
}

th {
  color:#6C757D;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-variant: small-caps;
  padding:0px 10px !important;
}

td {
  color:#4190BC;
  padding:0px 20px !important;
}

tbody tr {
  border-color: #E9ECEF;
  border-style: solid;
  border-width: 1px;
  vertical-align: center !important;
}

.react-bootstrap-table,table,thead,th {
  border: none !important;
}

.filter {
  background-color: #F8F9FA;
  border: none;
  height: 42px;
}

#patient-search-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  width: 300px;
  height: 36px;

  /* Global/09. White */

  background: #FFFFFF;
  /* Components/Forms/Input border */

  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 4px;
}

#profile {
  display: flex;
  align-items: center;
  flex-flow: nowrap;
  justify-content: right;
}

#patient-add-button {
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  margin-left: 25px;
}

#root {
  background-color: #F8F9FA;
  min-height: 100%;
  position: relative; /* needed for footer positioning*/
  height: auto !important; /* real browsers */
  min-height: 100%; /* real browsers */
}

#footer {
  background-color: #F8F9FA;
  height: 200px;
  width:100%;
  position: absolute;
  bottom: 0px;
}

#container {
  padding-bottom: 200px;
}

#pageDropDown {
  background-color: transparent;
  border:none;
  color: #6C757D;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 10px;
}

.login-container {
  color: #FFFFFF;
}

.login-container a {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  text-decoration-line: underline;
}

.register-login-flex {
  display: flex;
  justify-content: space-between;
}

.login-container-bg {
  background: url('../images/login_bg.svg') no-repeat;
  background-size: cover;
  background-position: bottom;
  margin: 0px -50px;
  padding: 0px 50px;
  padding-bottom: 250px;
}

.login-container {
  display: flex;
  flex-flow: wrap-reverse;
}

.login-left-side {
  max-width: 530px;
  margin: auto;
}

.login-right-side {
  max-width: 530px;
  margin: auto;
}

.navigation {
  width:100%;
  display: flex;
  justify-content: space-between;
}

.navigation > * {
  flex: flex-shrink;
}

.login-container-bg .nav-link {
  justify-content: right;
  margin: auto 50px auto auto;
}

.navbar-logo {
  justify-content: left;
  margin: 35px auto auto 31px;
}

.big-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}

.patient-container {
  flex: 1 1 auto;
  background-color:white;
  border: 1px solid #DEE2E6;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  padding-top: 20px;
  border-top: none;
  padding-bottom: 200px;
}

.nav-tabs {
  bottom: 0px;
}

.nav-tabs a {
  color: #215576;
}

#pcc-login {
  width: 291px;
  height: 60px;
  margin: 20px auto;
}

#medication-table td {
  color:#343A40;
}

.btn-outline-primary {
  color: #4190BC;
}

.dropdown-toggle {
  text-align: center;
}

.patient-info-container {
  display: flex;
  justify-content: space-between;
}

.patient-info {
  margin-bottom:0px;
  margin-top:0.5em;
  margin-left:1em;
  display: flex;
  align-items: baseline;
}

.patient-info h1 {
  margin:0px 15px 0px 0px;
}

.patient-info-buttons {
  display: flex;
}

.collapse.show{
  border: 1px;
  border-style: solid;
  border-color: #215576;
}
.card-title {
  vertical-align: center;
  line-height: 19px;
}
.condition-card-header,.condition-card-header-collapse {
  cursor:pointer;
  border:none;
  border-radius:4px;
  height:48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.condition-card-header-collapse {
  color:white;
  background-color: #215576;
}

.condition-card-header {
  color:#6C757D;
  background-color:#F6FBFF
}

.card-title {
  margin: 0px;
  height: 19px;
  display: flex;
  align-items: center;
}

.outcome-meds-table td {
  background: #FFFFFF;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 4px;
}

.recommendation_card_body a {
  color: blue;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    h1, h2, div, b
    {
      font: black !important;
    }
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.collapsible:hover {
  background-color: #ccc;
}

/* Style the collapsible content. Note: hidden by default */
.collapsible-content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
}

.cursorDefault {
  cursor:default !important;
}
div.dropdown-menu.show {
  cursor:default !important;
}

.error-input  {
  background-color: #F8D7DA;
  color: #DC3545;
}

.error-field .form-check-input {
  background-color: #F8D7DA;
  color: #DC3545;
}

.error-field div[class$="-control"] {
  background-color: #F8D7DA;
  color: #DC3545;
}

.search-horizontal-line {
  margin: 0;
  color: #CED4DA;
}

.search-text-blue {
  color: #80BDFF;
}

.medsafer__login--image {
  max-width: 100%;
}


@media (max-width: 350px) {
  .login-container-bg .nav-link {
    margin: auto auto auto auto;
  }
}